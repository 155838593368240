// import axios from "axios"
import React from "react" // , { useEffect, useState }
import MetaHeader from "../components/MetaHeader"
import styled from "styled-components"
import { navigate } from "gatsby"
import BigScreenMapImage from "../components/BigScreenMapImage"
import _c from "../uiConfig/colors"
import { Container, Typography, Button, Box } from "@material-ui/core"

export default function Home() {
  return (
    <MetaHeader>
      <StyledContainer>
        <Center component={"div"}>
          <Typography
            style={{ fontFamily: "Anton" }}
            variant="h4"
            component="h1"
            color={"primary"}
            gutterBottom
          >
            {"Cold Call List \nGenerator"}
          </Typography>
          <StyledTypography
            variant="subtitle1"
            component="p"
            color={"secondary"}
            gutterBottom
          >
            Use this tool to help you generate cold calling list in your area.
          </StyledTypography>
          <StyledTypography
            variant="subtitle1"
            component="p"
            color={"secondary"}
            gutterBottom
          >
            Please fill out order form to receive a detailed list of potential
            businesses in your local area.
          </StyledTypography>
          <StyledTypography
            variant="subtitle1"
            component="p"
            color={"secondary"}
            gutterBottom
          >
            Service will create
            <StyledBox component="span">{" .xlsx "}</StyledBox>
            and
            <StyledBox component="span">{" .pdf "}</StyledBox>
            files for you to download and use.
          </StyledTypography>
          <BtnContainer>
            <StyledButton
              onClick={e => {
                e.preventDefault()
                navigate("/filters")
              }}
              variant={"contained"}
              color={"primary"}
            >
              Generate List
            </StyledButton>
          </BtnContainer>
          {/* <WarningText
            variant="subtitle1"
            component="p"
            color={"secondary"}
            gutterBottom
          >
            Location List Generator is a paid service. It will require your card
            information in order to download the generated list
          </WarningText> */}
        </Center>
      </StyledContainer>
      <BigScreenMapImage />
    </MetaHeader>
  )
}
const StyledButton = styled(Button)`
  /* background-color: #ffd500; */
  background-color: #ffbf00;
  &:hover {
    background-color: #eeae00;
  }
  color: #fff;
  /* padding: 7px 14px; */
  height: 36px !important;
  border-radius: 5px;
  margin-bottom: 60px;
  min-width: 100%;
  text-transform: none;
  font-family: Work Sans;
`
const BtnContainer = styled.div`
  flex: 1;
  display: flex;
  /* max-height: 20% !important; */
  height: 36px !important;
  flex-direction: column;
  justify-content: flex-end;
  @media screen and (min-width: 800px) {
    padding-right: 50%;
  }
`

const StyledTypography = styled(Typography)`
  font-family: Work Sans;
  font-weight: 200;
  margin-bottom: 10px;
`
// const WarningText = styled(Typography)`
//   font-family: Work Sans;
//   font-weight: 200;
//   text-align: center;
//   font-size: 0.8em;
//   @media screen and (min-width: 650px) {
//     text-align: left;
//   }
// `
const StyledBox = styled(Box)`
  font-weight: 300;
  font-family: Work Sans;
`

const Center = styled(Box)`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 20%;
  justify-content: space-around;
  @media screen and (min-width: 600px) {
    padding-top: 0;
    height: 50%;
    flex: 0.5;
  }
  @media screen and (max-width: 600px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`

const StyledContainer = styled(Container)`
  min-height: 100vh;
  max-height: 100vh;
  background-color: ${_c.screen_background};
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: scroll;
  width: 60vw;
  padding: 0 10%;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    padding: 0 5%;
  }
  @media screen and (max-width: 600px) {
    padding: 0;
    /* margin: auto 5%; */
    width: 100vw;
    justify-content: flex-start;
  }
`
