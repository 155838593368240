import React from "react"
import styled from "styled-components"

const BigScreenMapImage = ({ params }) => (
  <StyledImg height={"100vh"} width={"40vh"} src={"/map.svg"} alt="map_image" />
)

const StyledImg = styled.img`
  display: none;
  @media screen and (min-width: 650px) {
    display: block;
    height: 100vh;
    width: 40vw;
    object-fit: cover;
  }
`
export default BigScreenMapImage
